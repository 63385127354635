<template>
    <div class="container">
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div>
            <el-button type="primary" @click="add()">添加广告</el-button>
            <div style="float: right">
                <div class="search">
                    <el-select placeholder="选择状态">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="显示" :value="2"></el-option>
                    </el-select>
                    <div style="float: right">
                        <el-input placeholder="请输入关键字" clearable></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="itemSet">
            <el-checkbox v-model="checked"></el-checkbox>
            <el-button size="mini" icon="el-icon-view" style="margin-left:10px">显示</el-button>
            <el-button size="mini" icon="el-icon-search">隐藏</el-button>
            <el-button size="mini" icon="el-icon-delete">删除</el-button>
        </div>

        <el-table :data="tableData" tooltip-effect="dark" style="width: 100%;margin-top:10px">
            <el-table-column width="55" type="selection"></el-table-column>
            <el-table-column label="顺序" width="120">
                <template>1</template>
            </el-table-column>
            <el-table-column prop="name" label="标题" width="120"></el-table-column>
            <el-table-column prop="address" label="链接" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="发布端" width="120"></el-table-column>
            <el-table-column prop="name" label="显示" width="120">
                <template>
                    <el-button type="primary" size="mini">显示</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" fixed="right">
                <el-button type="text">编辑</el-button>
                <el-button type="text">删除</el-button>
            </el-table-column>
        </el-table>
        <el-dialog title="添加新广告" :visible.sync="addVisible" width="50%">
            <el-form
                ref="form"
                v-bind:rules="rules"
                v-bind:model="form"
                label-position="right"
                label-suffix=":"
                label-width="100px"
                size="large"
            >
                <!-- <el-form
                ref="form"
                v-bind:rules="rules"
                v-bind:model="form"
                v-loading.body="loading"
                label-position="right"
                label-suffix=":"
                label-width="100px"
                size="large"
                element-loading-text="上传中"
                >-->
                <el-form-item label="排序">
                    <el-input placeholder="数字越大排名越靠前"></el-input>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input placeholder="请输入广告名称"></el-input>
                </el-form-item>
                <el-form-item label="上传图片">
                    <el-upload
                        class="avatar-uploader"
                        action="http://139.224.25.39:9110/mobile/file/uploadImage"
                        v-bind:show-file-list="false"
                        v-bind:on-success="uploadLogoImgSuccess"
                        v-bind:before-upload="beforeUploadLogoImg"
                    >
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="广告链接">
                    <el-input placeholder="请输入广告链接"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio label="1" v-model="radio">显示</el-radio>
                    <el-radio label="2" v-model="radio">隐藏</el-radio>
                </el-form-item>
                <el-form-item label="发布端">
                    <el-radio label="1" v-model="radio">APP端</el-radio>
                    <el-radio label="2" v-model="radio">微商城小程序</el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">发布</el-button>
                    <el-button>取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
export default {
    name: 'advert',
    components: {
        Breadcrumb
    },
    data () {
        return {
            tableData: [
                {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-08',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-06',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                },
                {
                    date: '2016-05-07',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }
            ],
            checked: false,
            addVisible: false,
            radio: '1'
        };
    },
    methods: {
        add () {
            this.addVisible = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.itemSet {
    margin-top: 20px;
    font-size: 14px;
}
</style>